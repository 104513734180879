import { Button, Flex } from '@chakra-ui/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
// import useTranslation from 'next-translate/useTranslation';
import { FiArrowLeft } from 'react-icons/fi';

import Headline from '@/components/Text/Headline';
// import { loadNamespaces } from '@/pages/_app';

const Custom404 = () => {
    const router = useRouter();
    // const { t } = useTranslation();

    return (
        <>
            <Head>
                <title>Oops !</title>
                <meta content="Oops !" name="description" />
            </Head>
            <Flex alignItems="center" height="100vh" justify="center" width="100%">
                <Flex alignItems="center" direction="column">
                    <Headline mb="2rem" textAlign="center">
                        404 | Page Not Found
                    </Headline>
                    <Button
                        leftIcon={<FiArrowLeft />}
                        onClick={() => router.replace('/')}
                        variant="link">
                        Go home
                    </Button>
                </Flex>
            </Flex>
        </>
    );
};

// export async function getStaticProps({ locale }) {
//     return {
//         props: {
//             _ns: await loadNamespaces(['common'], locale),
//             defaultMenu: true
//         }
//     };
// }

export default Custom404;
