import { Text } from '@chakra-ui/react';

const Headline = ({ children, ...props }) => {
    return (
        <Text fontSize="3xl" fontWeight="bold" {...props}>
            {children}
        </Text>
    );
};

export const SubHeadline = ({ children, ...props }) => {
    return (
        <Text fontSize="xl" fontWeight="bold" {...props}>
            {children}
        </Text>
    );
};

export const CustomText = ({ children, ...props }) => (
    <Text mb="1rem" fontSize={['0.7rem', '0.9rem']} fontWeight="500" color="gray.700" {...props}>
        {children}
    </Text>
);

export const CustomSubHeadline = ({ children, ...props }) => (
    <SubHeadline mt="2rem" mb="0.5rem" {...props}>
        {children}
    </SubHeadline>
);

export default Headline;
